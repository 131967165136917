import React from "react";
import BeiAn from "../components/BeiAn";
import './rights.css'

const WebRights = (props) => {
    const {

    } = props

    // return (
    //     <div className={'rights'}>
    //         <label className={'copy-right'}>© 上海印搜文化传媒版权所有</label>
    //         <BeiAn className={'bei-an'} beiAnName={'沪ICP备18018458号-1'}/>
    //     </div>
    // )

    return (
        <div className={'rights'}>
            <div className={'flex-layout'}>
                <label className={'copy-right'}>© 上海印搜文化传媒版权所有</label>
                <BeiAn className={'bei-an'} beiAnName={'沪ICP备18018458号-1'}/>
            </div>
        </div>
    )
}

export default WebRights
