import React from "react";

const BeiAn = (props) => {
    const {
        beiAnName,
        link = 'https://beian.miit.gov.cn'
    } = props

    return (
        <a href={link}>{beiAnName}</a>
    )
}

export default BeiAn
